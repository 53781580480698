.reactMarkdown h1 {
    @apply text-4xl md:text-6xl font-black font-extra
}

.reactMarkdown h2 {
    @apply text-3xl font-medium pt-5
}

.reactMarkdown h3 {
    @apply text-2xl pt-3
}

.reactMarkdown p {
    @apply text-lg
}

.reactMarkdown ul {
    @apply list-inside list-disc
}

.reactMarkdown li {
    @apply text-lg
}

.reactMarkdown ol {
    @apply list-inside list-decimal
}